import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import RichPost from 'components/richText';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const Airfield = ({ data, pageContext, preview, location }) => {
  const fsExcludeFlag = preview ? 0 : pageContext.airfield_data && pageContext.airfield_data.node.fs_exclude_flag;

  useEffect(() => {
    localStorage.setItem("excludeFS", fsExcludeFlag)
  }, [])

  let airfieldForFlightSearch;
  let airfieldLocationData;
  let defaultWidget = [];

  const language = preview ? data.prismicAirfield.lang : pageContext.lang;

  const prismicData = data.prismicAirfield;
  const slices = prismicData.data.body;
  const richContent = preview ? prismicData.data.post : prismicData.data.post.raw
  
  const alternateLanguages = preview ? data.prismicAirfield.alternate_languages : pageContext.alternateLanguages;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  const heroImage = prismicData.data.hero_image;

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  // set sidbar widgets on a airfield document via widget slices
  const widgetSlice = slices ? slices.filter(el => el.slice_type === 'widget') : [];
  // if slices are not available, apply the default widget from airfield properties document
  const widgetFromProperties = !preview && widgetSlice.length === 0 && filterPrismicDataByLanguage(data.allPrismicPropertiesAirfield.edges, language);

   if (preview) {
    defaultWidget = data?.allPrismicWidgetData?.edges;
   } else {
     if (widgetFromProperties?.node?.data?.body) {
       defaultWidget = widgetFromProperties?.node?.data?.body;
     }
   }

  const widgetData = preview ? defaultWidget : widgetSlice.length > 0 ? widgetSlice : defaultWidget.length > 0 ? defaultWidget : [];

  const bannerImageSlice = slices && slices.find(el => {
    if (el.slice_type === "banner_image") return el
  });
  
  const tableOfContentsSlice = slices && slices.filter(el => el.slice_type === "table_of_contents");

  let headData = {
    meta_title: prismicData.data.meta_title,
    meta_description: prismicData.data.meta_description,
    meta_keywords: prismicData.data.meta_keywords,
  }

  if (!preview) {
    airfieldForFlightSearch = pageContext.airfield_data ? `${ pageContext.airfield_data.node.airfield_name} (${ pageContext.airfield_data.node.country_name}) ${pageContext.airfield_data.node.icao_code}/${pageContext.airfield_data.node.iata_code}` : null

    airfieldLocationData = {
      cityName: pageContext.airfield_data ? pageContext.airfield_data.node.city_name : null,
      countryName: pageContext.airfield_data ? pageContext.airfield_data.node.country_name : null,
      latitude: pageContext.airfield_data ? pageContext.airfield_data.node.latitude : null,
      longitude: pageContext.airfield_data ? pageContext.airfield_data.node.longitude : null,
      iata_code: pageContext.airfield_data ? pageContext.airfield_data.node.iata_code : null,
      icao_code: pageContext.airfield_data ? pageContext.airfield_data.node.icao_code : null,
      time_zone: pageContext.airfield_data ? pageContext.airfield_data.node.time_zone : null
    }
  }

  // add open graph meta data if available
  if(prismicData?.data?.body2?.length > 0) {
    const filteredOpenGraphData = prismicData.data.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }
  
  return (
    <RenderPostWrapper 
      showAddress={true} 
      heroImage={heroImage}
      bannerImageSlice={bannerImageSlice}
      tableOfContentsSlice={tableOfContentsSlice}
      preview={preview}
      location={location}
      meta={headData}
      airfieldLocationData={airfieldLocationData}
      airfieldForFlightSearch={airfieldForFlightSearch}
      airfieldId={prismicData.data.airfield_id}
      alternateLanguages={alternateLanguages}
      language={language}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      fsExcludeFlag={fsExcludeFlag}
      widget={widgetData}
      showFlightSearchWidget={false}
      component={
        <React.Fragment>
          { fsExcludeFlag ? <div id="fs-exclude" hidden>{fsExcludeFlag}</div> : null }

          <RichPost 
            preview={preview}
            slices={slices}
            richContent={richContent}
            language={language}
          />          
        </React.Fragment>
      }
    />
  );
};

export default Airfield;

Airfield.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool,
}

export const query = graphql`
  query airfieldQuery($id: String!) {
    prismicAirfield(id: { eq: $id }) {
      ...PrismicAirfield
    }
    allPrismicPropertiesAirfield {
      edges {
        node {
          ...PrismicPropertiesAirfield
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          lang
          data {
            phone_local
            localised_phone {
              country_code
              phone_number
            }
          }
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
  }
`;
